<template>
  <div>
    <el-dialog title="分派教师"
               v-if="dialogVisible"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">
      <el-form :model="form"
               ref="formRef"
               v-if="dialogVisible"
               label-width="100px"
               :rules="rules">
        <el-row>
          <el-form-item label="选择教师"
                        prop="edu_user_id">
            <el-select v-model="form.edu_user_id"
                       filterable
                       style="width:100%"
                       placeholder="请输入关键词">
              <el-option v-for="item in teacherList"
                         :key="item.edu_user_id"
                         :label="`${item.edu_user_mobile} ${item.edu_user_nickname}`"
                         :value="item.edu_user_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>

        <el-row>
          <el-form-item label="截止时间">
            <el-date-picker v-model="form.end_time"
                            type="datetime"
                            @focus="$forbid"
                            style="width:100%"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-row>

        <el-row>
          <el-form-item label="审核人"
                        prop="edu_user_id">
            <el-select v-model="form.audit_ids"
                       filterable
                       multiple
                       style="width:100%"
                       placeholder="请选择审核人">
              <el-option v-for="item in auditTeacherList"
                         :key="item.edu_user_id"
                         :label="`${item.edu_user_mobile} ${item.edu_user_nickname}`"
                         :value="item.edu_user_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
      </el-form>
      <el-button type="primary"
                 @click="submit">提交</el-button>

    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      dialogVisible: false,
      // 用来存储新的题目信息
      innerVisible: false,
      // 用来存储老的微课数据
      form: {},
      rules: {
        edu_user_id: [
          { required: true, message: '请选择教师', trigger: 'blur' },
        ]
      },
      teacherList: [],
      auditTeacherList: []
    }
  },
  mounted () {
  },
  methods: {
    getAuditTeacherList (id) {
      this.$http({
        url: '/api/v1/course/appoint_audit_lst',
        method: 'get',
        params: {
          subject_id: id
        }
      }).then(res => {
        this.auditTeacherList = res.data
      })
    },
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    // save () {
    //   this.$http({
    //     url: '/api/v1/course/assign_teacher',
    //     method: 'post',
    //     data: form
    //   }).then(res => {
    //     this.$notify({
    //       title: '提示',
    //       message: '修改成功!',
    //       type: 'success'
    //     });
    //     this.handleClose()
    //     this.$parent.search()
    //   })
    // },
    getTeacher () {
      this.$http({
        url: '/api/v1/course/teacher_lst',
        method: 'GET',
      }).then(res => {
        this.teacherList = res.data.list
      })
    },
    submit () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form.audit_ids = form.audit_ids.join(',')
        this.$http({
          url: '/api/v1/course/assign_teacher',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '分派成功!',
            type: 'success'
          });
          this.handleClose()
          this.$parent.search()
        })
      })
    },

  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}

::v-deep .el-table__body tr.current-row > td {
  background: #d8eaff !important;
}
</style>